<template>
  <div>
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-primary"
        class="mr-1"
      >
        <feather-icon
          icon="ShoppingCartIcon"
          size="20"
        />

      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ faq.header.title }}
        </h4>
        <span>{{ faq.header.subtitle }}</span>
      </div>
    </div>

    <!-- collapse -->
    <app-collapse
      id="faq-payment-qna"
      accordion
      type="margin"
      class="mt-2"
    >

      <app-collapse-item
        v-for="(data, index) in faq.questions"
        :key="index"
        :title="data.question"
      >
        <div v-html="data.answer" />
      </app-collapse-item>

    </app-collapse>

    <!--/ collapse -->
  </div>

</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import data from './pricing-faq.json'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      faq: data,
    }
  },
}
</script>
