<template>
  <b-row>

    <b-col
      v-if="!me.organization.subscriptionActive"
      cols="12"
      xl="10"
    >
      <b-alert
        show
        variant="danger"
        class="p-1 mb-2 text-center shadow"
      >
        <p>
          <span v-if="me.organization.subscriptionType === 'Trial'">Twój darmowy okres próbny zakończył sie</span>
          <span v-else>Twoja subskrypcja zakończyła sie</span>

          {{ transformDateToHumanReadablePretty(me.organization.subscriptionEndsAt) }},
          o godzinie
          {{ transformTimeToHumanReadablePretty(me.organization.subscriptionEndsAt) }}.
          Aby móc nadal korzystać z aplikacji, wybierz poniżej płatność roczną lub miesięczną, a następnie przejdź do płatności, w celu uruchomienia subskrypcji.
        </p>
        <p>
          Jeżeli masz pytania, pisz do nas na adres
          <a
            class="text-danger"
            href="mailto:hi@totedi.com"
            target="_blank"
          >
            <b>hi@totedi.com</b>
          </a>.
        </p>
      </b-alert>
    </b-col>

    <b-col xl="10">
      <b-card>
        <b-row>
          <b-col
            md="6"
            class="mb-5"
          >

            <b-row>
              <b-col
                cols="10"
                offset="1"
                class="text-center mt-1 d-inline-flex justify-content-center"
              >
                <b-button
                  :variant="selected === 'Monthly' ? 'outline-primary' : 'flat-secondary'"
                  class="mx-1"
                  size="sm"
                  pill
                  style="flex: 1 1 0;"
                  @click="selected = 'Monthly'"
                >
                  Miesięczna
                </b-button>
                <b-button
                  :variant="selected === 'Yearly' ? 'outline-primary' : 'flat-secondary'"
                  class="mx-1"
                  size="sm"
                  pill
                  style="flex: 1 1 0;"
                  @click="selected = 'Yearly'"
                >
                  Roczna
                </b-button>
              </b-col>
            </b-row>
            <div
              v-if="selected === 'Monthly'"
              class="font-large-5 text-center mb-n1 font-weight-bold mt-2"
            >
              <span>
                {{ priceMonthly }}
              </span>
              <span class="period font-large-2">
                zł
              </span>
            </div>
            <div
              v-if="selected === 'Yearly'"
              class="font-large-5 text-center mb-n1 font-weight-bold mt-2"
            >
              <span>
                {{ priceMonthly }}
              </span>
              <span class="period font-large-2">
                zł
              </span>
            </div>
            <div class="text-center font-medium-2">
              / miesiąc
              <span v-if="parseInt(additionalEducators) > 1">
                za {{ parseInt(additionalEducators) }} nauczycieli
              </span>
            </div>

            <p
              v-if="selected === 'Yearly'"
              class="mt-1 mb-2 text-center text-muted"
            >
              {{ priceYearly }} zł / rok
            </p>

            <p
              v-if="selected === 'Monthly'"
              class="mt-1 mb-2 text-danger text-center"
            >
              Zaoszczędź 20% wybierając płatność roczną.
            </p>

            <b-row>
              <b-col
                cols="10"
                offset="1"
              >
                <div class="d-flex justify-content-between text-center">
                  <span>1</span>
                  <span>5</span>
                  <span>10</span>
                </div>
              </b-col>
              <b-col
                cols="10"
                offset="1"
                class="text-center"
              >
                <b-form-input
                  v-model="additionalEducators"
                  type="range"
                  min="1"
                  max="10"
                />
                Liczba nauczycieli
              </b-col>
            </b-row>
            <!--            <VSlider-->
            <!--              v-model="value"-->
            <!--              :error="value > 40"-->
            <!--              :rules="rules"-->
            <!--              :step="10"-->
            <!--              thumb-label="always"-->
            <!--              show-ticks-->
            <!--            />-->

            <div class="mt-3 text-center">
              <b-button
                variant="primary"
                :disabled="loadingRedirectUrl"
                @click="redirect"
              >Przejdź do płatności</b-button>
            </div>
          </b-col>
          <b-col md="6">
            <h4 class="mt-2">
              W cenie otrzymujesz
            </h4>
            <ul class=" text-left mt-2">
              <li class="mb-25">
                Nielimitowaną liczbę spotkań
              </li>
              <li class="mb-25">
                Nielimitowaną liczbę uczniów
              </li>
              <li class="mb-25">
                Darmowe konta w aplikacji dla wszystkich uczniów
              </li>
              <li class="mb-25">
                Zarządzanie uczniami i usługami
              </li>
              <li class="mb-25">
                Zaawansowany kalendarz
              </li>
              <li class="mb-25">
                Narzędzie do udostępniania materiałów edukacyjne
              </li>
              <li class="mb-25">
                Automatyzację powiadomień
              </li>
              <li class="mb-25">
                Zarządzanie nauczycielami
              </li>
              <li
                v-for="capability in capabilities.list"
                v-show="capability.soon"
                :key="capability.id"
                class="mb-25"
              >
                <span v-html="capability.heading" />
                <b-badge
                  size="sm"
                  variant="light-primary"
                  class="ml-50"
                >
                  Wkrótce
                </b-badge>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card>

      <PricingFaq />
    </b-col>
  </b-row>
</template>

<script>
import dayjs from 'dayjs'
import capabilities from './checkout/capabilities.json'
import PricingFaq from '@/views/auth/checkout/PricingFaq'
import { transformDateToHumanReadablePretty, transformTimeToHumanReadablePretty } from '@/helpers/dateTimeHelper'
import { parseRequestError } from '@/helpers/helpers'

export default {
  components: {
    PricingFaq,
  },
  data() {
    return {
      selected: 'Monthly',
      loadingRedirectUrl: false,
      additionalEducators: 1,
      options: [
        { text: 'wybieram płatność za rok z góry', value: 'Yearly' },
        { text: 'wybieram płatność co miesiąc', value: 'Monthly' },
      ],
    }
  },
  computed: {
    capabilities() {
      return capabilities
    },
    priceMonthly() {
      if (this.selected === 'Yearly') {
        return 60 + (20 * (this.additionalEducators - 1))
      }
      return 75 + (25 * (this.additionalEducators - 1))
    },
    priceYearly() {
      if (this.selected === 'Yearly') {
        return 720 + (240 * (this.additionalEducators - 1))
      }
      return 900 + (300 * (this.additionalEducators - 1))
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeMount() {
    if (this.me.organization.paidsubscriptionActive) {
      this.$router.replace({ name: 'dashboard-redirect' })
    }
  },
  methods: {
    dayjs,
    transformDateToHumanReadablePretty,
    transformTimeToHumanReadablePretty,
    redirect() {
      this.loadingRedirectUrl = true
      this.$store.dispatch(
        'auth/prepareRedirectUrl',
        {
          subscriptionInterval: this.selected,
        },
      )
        .then(response => {
          window.location.href = response.data.checkoutUrl
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loadingRedirectUrl = false
        })
    },
  },
}
</script>
